<template>
  <div>
    <v-alert text dense color="info" border="left" style="font-size: 14px">
      {{ $t('labels.remove_handover_code') }}
    </v-alert>
    <v-row>
      <v-col cols="6">
        <v-autocomplete v-model="id_delivery_company" :items="deliveryCompanies"
                        :label="$t('labels.delivery_company')" dense outlined hide-details
                        @change="onDeliveryChange">
          <template v-slot:selection="data">
            <div v-html="data.item.text" style="font-size: 12px; font-weight: 500"></div>
          </template>
          <template v-slot:item="data">
            <div v-html="data.item.text" style="font-size: 12px; font-weight: 500"></div>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="code" :disabled="!id_delivery_company" ref="inputCodeRef"
                      :label="placeholder" dense outlined hide-details
                      @keyup.enter="scanTracking"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-btn color="error" block @click="removeHandover('all')" :disabled="orders.length === 0">
          {{ $t('labels.clear') }}
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" block @click="confirmHandover" :disabled="isDisabledBtnSubmit">
          {{ $t('labels.confirm') }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-card
            class="mx-auto"
            outlined
        >
          <v-list-item three-line>
            <v-list-item-content>

              <div class="text-overline mb-1 error--text">
                {{ $t('labels.cancelled') }}: {{ requestCancelledOrders.length }}
              </div>
              <div>
                <v-chip
                    class="mr-1 mb-1"
                    color="errorClear"
                    v-for="order in requestCancelledOrders"
                    :key="order.tracking_id"
                >
                  <OrderTracking style="color: red" :tracking-id="order.tracking_id" />
                  <span class="black--text">&nbsp; {{ $t('labels.basket') }}: {{ order.basket_code }} - {{ $t('labels.position') }}: {{ findIndexBasket(order) }}</span>
                </v-chip>
              </div>

              <div class="text-overline mt-2 mb-1">
                {{ $t('labels.sum') }}: {{ orders.length }}
                <span v-if="orders.length > limitOrders.length">({{ limitOrders.length }} {{ $t('labels.nearest_order') }})</span>
              </div>
              <div>
                <v-chip
                    class="mr-1 mb-1"
                    :color="order.request_cancel ?  'error' : 'default'"
                    v-for="order in limitOrders"
                    :key="order.tracking_id"
                >
                  {{ order.tracking_id }}
                  <small v-if="order.count_group > 0" class="error--text ml-1"> ({{ order.count_group_scanned }}/{{ order.count_group }})</small>
                </v-chip>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <HandoverHistory class="mt-10" />

    <v-dialog v-model="dialogConfirmRemove" persistent max-width="350px">
      <ConfirmRemoveBox
          v-if="dialogConfirmRemove"
          :title="$t('labels.remove_handover_order')"
          :description="$t('messages.remove_handover_order')"
          :label="$t('labels.tracking_and_confirm_code')"
          :placeholder="$t('labels.tracking_and_confirm_code')"
          :codes="removeCodes"
          @cancel="cancelConfirmRemove"
          @confirm="confirmRemove"
      />
    </v-dialog>
  </div>
</template>

<script>
import {httpClient} from "@/libs/http";
import {BARCODE_REMOVE_HANDOVER} from "@/libs/const";
import _ from "lodash";
import moment from "moment";

export default {
  name: "Handover",
  components: {
    HandoverHistory: () => import('@/components/order_online/HandoverHistory'),
    ConfirmRemoveBox: () => import('@/components/common/ConfirmRemoveBox'),
    OrderTracking: () => import('@/components/common/OrderTracking'),
  },
  data: () => ({
    isLoading: false,
    deliveryCompanies: [],
    id_delivery_company: null,
    code: null,
    orders: [],
    dialogConfirmRemove: false,
    basket: {},
    lastHandoverId: null
  }),
  computed: {
    placeholder() {
      if (!this.basket || !this.basket.id) {
        return this.$t('labels.basket_code')
      }
      return this.$t('labels.basket_or_order_code')
    },
    removeCodes() {
      return [BARCODE_REMOVE_HANDOVER]
    },
    trackingIds() {
      return [...this.orders].map(order => (order.tracking_id))
    },
    groupByBasket() {
      return _.groupBy([...this.orders], 'handover_id_basket')
    },
    requestCancelledOrders() {
      return [...this.orders].filter(order => order.request_cancel === 1)
    },
    groupScanningOrders() {
      return [...this.orders].filter(order => order.count_group > order.count_group_scanned)
    },
    limitOrders() {
      if (this.orders.length > 20) {
        return [...this.orders].splice(0, 20)
      }
      return [...this.orders]
    },
    isDisabledBtnSubmit() {
      return this.orders.length === 0 || this.requestCancelledOrders.length > 0 || this.groupScanningOrders.length > 0
    }
  },
  methods: {
    findIndexBasket(order) {
      const basketOrders = [...this.groupByBasket[order.handover_id_basket]].map(od => {
        od.handover_scanned_at = moment(od.handover_scanned_at).unix()
        return od
      })
      const basketOrdersX = _.orderBy(basketOrders, ['handover_scanned_at'], ['asc'])
      const indexBasket = basketOrdersX.findIndex(ob => ob.id === order.id)
      return (indexBasket + 1)
    },
    async getDeliveryCompanies() {
      const { data } = await httpClient.post('/order-online-get-delivery-by-state-check-cancel')
      this.deliveryCompanies = data.map(e => {
        let text = `<span style="display:inline-block;min-width:110px">${e.delivery_company}</span>`
        text += `   ${+e.count_order + +e.count_order_cancelled} = `
        text += ` <span class="green--text">${e.count_order}</span> (<span class="grey--text font-weight-regular">cần BGVC</span>)`
        text += ` + <span class="error--text">${e.count_order_cancelled}</span> (<span class="grey--text font-weight-regular">đã hủy</span>)`
        if (e.count_order || e.count_order_cancelled) {
          text += ` `
        }
        return {
          value: e.id_delivery_company,
          text: text
        }
      })
    },
    onDeliveryChange() {
      this.focusInputCode()
      this.getWaitingHandover()
    },
    async getWaitingHandover() {
      this.orders = []
      const { data } = await httpClient.post('/order-online-handover-get-waiting', {
        id_delivery_company: this.id_delivery_company,
      })
      this.orders = [...data]
      this.basket = {}
    },
    async scanTracking() {
      if (!this.code) {
        return false
      }
      if (this.code === BARCODE_REMOVE_HANDOVER) {
        this.showConfirmRemove()
        this.code = null
        return false
      }

      const checkIsset = this.orders.find(o => [o.tracking_id.toUpperCase(), (o.delivery_order_id && o.delivery_order_id.toUpperCase() || o.delivery_order_id)].includes(this.code.toUpperCase()))
      if (checkIsset) {
        this.code = null
        this.$vToastify.error(this.$t('labels.order_scanned'))
        this.$root.$emit('playAudioByName', 'DonDaQuet')
        return false
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        this.$root.$emit('playErrorAudio')
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/order-online-handover-scan', {
          code: this.code,
          id_delivery_company: this.id_delivery_company,
          id_basket: this.basket.id || null
        })

        this.code = null
        this.isLoading = false
        this.$root.$emit('playSuccessAudio')

        if (data.basket) {
          this.basket = {...data.basket}
          return false
        }

        const order = data.order
        if (!order) {
          this.$vToastify.error(this.$t('labels.order_code_not_found'))
          this.$root.$emit('playAudioByName', 'MaDonKhongTonTai')
          return false
        }
        if (order.count_group > 0) {
          this.orders = [...this.orders].filter(ord => ord.id !== order.id)
        }
        this.orders.unshift({...order})
      } catch (e) {
        this.code = null
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        switch (errMsg) {
          case 'OrderNotFound':
            this.$vToastify.error(this.$t('labels.order_code_not_found'))
            this.$root.$emit('playAudioByName', 'MaDonKhongTonTai')
            break;
          case 'OrderNotStatus':
            this.$vToastify.error(this.$t('labels.status_invalid'))
            this.$root.$emit('playAudioByName', 'TrangThaiKhongHopLe')
            break;
          case 'OrderErrorDelivery':
            this.$vToastify.error(this.$t('labels.order_wrong_delivery'))
            this.$root.$emit('playAudioByName', 'NhamDonRoi')
            break;
          case 'BasketErrorDelivery':
            this.$vToastify.error(this.$t('labels.basket_wrong_delivery'))
            this.$root.$emit('playAudioByName', 'NhamSotRoi')
            break;
          case 'OrderNotYou':
            this.$vToastify.error(this.$t('labels.no_permission_process'))
            this.$root.$emit('playAudioByName', 'KhongCoQuyenXuLy')
            break;
          case 'OrderRequestCancelled':
            this.$vToastify.error(this.$t('labels.order_cancelled_receive_return'))
            this.$root.$emit('playAudioByName', 'DonDaHuy')
            break;
          default:
            this.$vToastify.error(errMsg)
            this.$root.$emit('playErrorAudio')
        }
      }
    },

    focusInputCode() {
      setTimeout(() => {
        this.$refs.inputCodeRef.focus()
      }, 100)
    },

    async removeHandover(tracking) {
      if (tracking === 'all' && !confirm(this.$t('messages.confirm_clear_order_handover'))) {
        return false
      }
      let tracking_ids = []
      if (tracking === 'all') {
        tracking_ids = [...this.trackingIds]
      } else {
        tracking_ids = [tracking]
      }
      try {
        if (this.isLoading) {
          this.$vToastify.warning(this.$t('messages.loading'));
          return false
        }
        this.isLoading = true

        await httpClient.post('/order-online-handover-remove-scanned', {
          tracking_ids,
        })
        this.isLoading = false
        if (tracking === 'all') {
          this.orders = []
        } else {
          const orders = this.orders.filter(order => order.tracking_id !== tracking)
          this.orders = [...orders]
        }
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
      }
    },

    async confirmHandover() {
      try {
        if (this.isLoading) {
          this.$vToastify.warning(this.$t('messages.loading'));
          return false
        }
        this.isLoading = true

        const { data } = await httpClient.post('/order-online-handover-confirm', {
          tracking_ids: this.trackingIds,
          id_delivery_company: this.id_delivery_company,
        })

        this.lastHandoverId = data
        this.isLoading = false
        this.orders = []
        this.$root.$emit('playAudioByName', 'ChoBienBanBanGiao')
        this.$vToastify.success('Chờ chút nhé! Hệ thống đang xuất biên bản bàn giao')
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
        this.$root.$emit('playErrorAudio')
      }
    },

    showConfirmRemove() {
      this.dialogConfirmRemove = true
    },

    cancelConfirmRemove() {
      this.dialogConfirmRemove = false
      this.focusInputCode()
    },

    confirmRemove(confirm_code) {
      const order = [...this.orders].find(od => [od.tracking_id, od.delivery_order_id].includes(confirm_code))
      if (!order) {
        this.$vToastify.error(this.$t('labels.order_not_found'));
        this.$root.$emit('playErrorAudio');
        return false
      }
      this.removeHandover(order.tracking_id)
    },

    listenSocket () {
      window.socket.on('order_request_cancelled', data => {
        if (data.handover_scanned_id_employee && data.id_delivery_company && data.tracking_id && window.me && this.id_delivery_company) {
          if (data.handover_scanned_id_employee === window.me.identity_id && data.id_delivery_company === this.id_delivery_company) {
            this.$vToastify.warning(`Đơn hàng ${data.tracking_id} đã được yêu cầu hủy!`)
            this.getWaitingHandover()
          }
        }
      })
      window.socket.on('order_handover_success', data => {
        if (data.id_handover === this.lastHandoverId) {
          this.downloadExcelFile('/order-online-handover-file', data, 'A4_Bien_ban_ban_giao.xlsx')
          this.lastHandoverId = null
        }
      })
    },
  },
  mounted() {
    this.getDeliveryCompanies()
    // this.listenSocket()
  }
}
</script>

<style scoped>

</style>
